%info {
  position: absolute;
  color: #2196f3;
  font-size: 1rem !important;
}

.infoEdit {
  @extend %info;
  left: -8rem;
  top: 0.6rem;
}

.infoShow {
  @extend %info;
  left: 2rem;
  top: -1.3rem;
}
