.message {
  text-align: center;
}

.messageText {
  margin-left: 1.5rem;
}

.dropzone {
  margin-top: 1.5rem;
  p {
    max-width: 100%;
    word-wrap: break-word;
  }
}

.error {
  word-wrap: break-word;
}
