.button {
  margin: 1rem 1rem 0 !important;
}

.datagrid {
  width: 100% !important;
}

.autocomplete {
  min-width: 200px;
}
