.list {
  text-align: center;
  width: 100%;
  padding: 0;
}

.listItem {
  list-style: none;
  line-height: 2;
}

.listDate {
  margin-left: 1rem;
}
