.defaultFont {
  font-size: 14px;
}

.smFont {
  font-size: 12px;
}

.typo {
  text-transform: capitalize;
  margin: 1rem 0 0.5rem;
}

.divider {
  margin: 1rem 0 !important;
}

.modalTitle {
  > * {
    position: relative;
  }
}

.hide {
  display: none !important;
}

.closeModal {
  position: absolute;
  transform: translateY(-50%);
  top: 2rem;
  right: 1rem;
  cursor: pointer;
}

.error {
  color: #D32F2F;
}

.tagRemove {
  svg {
    z-index: 1000;
  }
}
