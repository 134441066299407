.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

[class*='RaFormInput-input'] {
  width: 100% !important; // scss-lint:disable ImportantRule
}

header {
  height: 48px;
}

main {
  line-height: 2.5;
  padding-top: 1rem;
}

#main-content,
.MuiToolbar-root {
  padding-top: 0 !important;
}

#main-content {
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.ag-theme-material {
  --ag-font-size: 14px !important;
  --ag-material-accent-color: #2196f3 !important;
  --ag-cell-horizontal-padding: 8px !important;
}