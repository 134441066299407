.container {
  margin-bottom: 10rem;
}

.chart {
  top: 10px;
  right: 20px;
  bottom: 10px;
  left: 0px;
  padding-top: 0.5rem;
}

.tooltip {
  font-size: 90%;
  border: 1px solid lightgray;
  padding: 0.5rem 1.5rem;
  box-shadow: 1px 2px 5px lightgray;
  background-color: white;
  border-radius: 2px;
}

.tooltipElement {
  margin: 0.5rem 0;
}

