.container {
  padding: 1.5rem 1rem 0 !important;
}

.link {
  width: 8rem;
}

.filter {
  min-width: 12rem;
}

.filterSmall {
  min-width: 6rem;
}