$color: #BDBDBD;
$border-color: #EEEEEE;
$bg-color: #FAFAFA;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $border-color;
  border-style: dashed;
  background-color: $bg-color;
  color: $color;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}
