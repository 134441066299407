.container {
  padding: 1.5rem 1.5rem 0;
}

.buttonWrapper {
  text-align: right;

  button {
    margin-left: 1rem;
  }
}

.bulkActions {
  display: flex;
  align-items: center;
  justify-content: center;
}
