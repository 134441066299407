.edit {
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  font-size: 1.2rem !important;
}

.information {
  margin-top: 1rem !important;
}

.separator {
  margin-top: 2.5rem;
}