.container {
  padding: 1.5rem 1rem 0 !important;
}

.block {
  display: block;
}

.placeholder {
  font-size: 80%;
}

.filters {
  display: inline-flex;
  flex-direction: row;
  padding: 16px;
  padding-left: 32px;
}

.loader {
  margin: 8rem 0;
}