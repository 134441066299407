$color: #BDBDBD;

.image {
  text-align: center;

  img {
    margin: 0.5rem;
    max-height: 10rem;
  }
}

.imageSVG {
  color: $color;
  height: 10rem !important;
  width: 5rem !important;
}
