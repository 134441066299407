$basis: 100%;
$edit: 80%;
$image: $basis - $edit;

.tab {
  width: 100%;
}

.edit {
  flex-basis: $edit;
}

.products {
  flex-basis: $basis;
}

.dropzone {
  margin-top: 0.5rem;
}

.imageEdit {
  height: 10rem;
  width: 5rem;
}

.autocomplete {
  min-width: 400px !important;
}

.newStatement {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-grow: 1;
}

.numberChipContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.numberChip {
  background-color: rgb(240, 240, 240);
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 8px;
  margin-right: 4px;
}

.separator {
  margin-top: 2.5rem;
}